<template>
  <div>
    <div class=""></div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
@import "./index.scss";
</style>
